import React, { Component } from 'react';
import './landing.css';
import turnipIcon from './turnip-large.png';

class Landing extends Component {
  render() {
    return (
      <div className="landing-container">
        <img src={turnipIcon} className="logo" alt="logo" />
        <h1>turnip</h1>
        <p>a collaborative song queue app</p>
        <a className="play-store-button" href='https://play.google.com/store/apps/details?id=ca.turnip.turnip&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'/></a>
        <div className="nav-links">
          <h2>
            <a href="#about">
              about
            </a>
          </h2>
          <h2>
            <a href="#contact">
              contact
            </a>
          </h2>
          <h2>
            <a href="#donate">
              donate
            </a>
          </h2>
        </div>
      </div>
    );
  }
}

export default Landing;
