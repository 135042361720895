// Libraries
import React, { Component } from 'react';

// Static assets
import './divider.css';

class Divider extends Component {
    render() {
        return (
            <div className="divider"></div>
        );
    }
}

export default Divider;