// Libraries
import React, { Component } from 'react';

// Static assets
import './contact.css';

// Constants
const mailtoPrefix = "mailto:";
const bugs = "bugs@turnipapp.com";
const features = "features@turnipapp.com";
const general = "general@turnipapp.com";

class Contact extends Component {
  render() {
    return (
      <div id="contact" className="contact-container">
        <h1>contact</h1>
        <div className="contact-body">
          <p>
            <a href={mailtoPrefix + bugs}>bug reports</a>{" - " + bugs}
          </p>
          <p className="contact-subtext">
            Please include a description of the bug, as well as of what you were doing in the app when the bug occurred.
          </p>
          <p>
            <a href={mailtoPrefix + features}>feature requests</a>{" - " + features}
          </p>
          <p className="contact-subtext">
            Feature requests will be considered and implemented at the author's discretion.
          </p>
          <p>
            <a href={mailtoPrefix + general}>general inquiries</a>{" - " + general}
          </p>
          <p className="contact-subtext">
            For all other questions, comments, spam, or anything else you want to send.
          </p>
        </div>
      </div>
    )
  }
}

export default Contact
