// Libraries
import React, { Component } from 'react';

// Local components
import About from './about';
import Contact from './contact';
import Divider from './divider';
import Donate from './donate';
import Landing from './landing';

// Static assets
import './app.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {users: []};
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="app-container">
        <Landing />
        <Divider />
        <About />
        <Contact />
        <Donate />
        <div className="copyright">copyright &copy; 2018 theodore brockman</div>
      </div>
    );
  }
}

export default App;
