// Libraries
import React, { Component } from 'react';

// Static assets
import './about.css';

class About extends Component {
  render() {
    return (
      <div className="about-container" id="about">
        <h1>
          about
        </h1>
        <div className="about-body">
          <p>
            <a href="https://play.google.com/store/apps/details?id=ca.turnip.turnip">turnip</a> is a free Android (sorry, no iOS support yet) app that can turn your device into a jukebox that allows anyone using the app nearby to queue their favorite songs. Hosting a jukebox requires a Spotify premium account, but everyone else can still add their music without having one.
          </p>
          <p>
            <a href="https://play.google.com/store/apps/details?id=ca.turnip.turnip">turnip</a> is developed and maintained by <a href="https://tbrockman.github.io">Theodore Brockman</a>, who hopes you enjoy using it.
          </p>
        </div>
      </div>
    )
  }
}

export default About
