// Libraries
import React, { Component } from 'react';

// Static assets
import './donate.css';
import qrImage from './qr.png';

class Donate extends Component {
  render() {
    return (
      <div id="donate" className="donate-container">
        <h1>donate</h1>
        <div className="donate-body">
          <p>Donations are definitely appreciated, but not at all expected.
          Any contributions received go towards server costs and allowing
          the developer more time to work on the project.</p>
          <p>Bitcoin:</p>
          <img src={qrImage} className="bitcoin-qr"
               alt="bitcoin:1ECFUgY16WuKsg2d1DKQmzt5SpLA9teuxV" />
          <p className="long-text">bitcoin:1ECFUgY16WuKsg2d1DKQmzt5SpLA9teuxV</p>
        </div>
      </div>
    )
  }
}

export default Donate;
